<template>
  <div class="loader-container">
    <div class="frame-wrapper" :style="`background: ${background}`">
      <div v-if="isBigSpinner && showLoader" class="sk-folding-cube">
        <div class="sk-cube1 sk-cube"></div>
        <div class="sk-cube2 sk-cube"></div>
        <div class="sk-cube4 sk-cube"></div>
        <div class="sk-cube3 sk-cube"></div>
      </div>
      <div v-else-if="!isBigSpinner && showLoader" class="loader"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpinnerLoader",
  props: {
    background: {
      type: String,
      default: "rgb(260, 260, 260)",
    },
    isBigSpinner: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    showLoader() {
      //For we are circles client, we do not want to display le loader
      // if (this.$route.params.siteId === "cddca02f-387a-4c4f-ae8f-34ab8748d5d2")
      //   return false;

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
$square1-color: #f3c2ff;
$square2-color: #e27cfc;
$square3-color: #af3ed8;
$square4-color: #7b01b5;

.frame-wrapper {
  height: 100%;
  width: 100%;

  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  z-index: 20;
}

.sk-folding-cube {
  height: 40px;
  width: 40px;
  margin: auto;
  position: relative;
  transform: rotateZ(45deg);

  .sk-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    transform: scale(1.1);
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $square1-color;
      animation: sk-foldCubeAngle 2.4s infinite linear both;
      transform-origin: 100% 100%;
    }
  }
  .sk-cube2 {
    transform: scale(1.1) rotateZ(90deg);
    &::before {
      background-color: $square2-color;
      animation-delay: 0.3s;
    }
  }
  .sk-cube3 {
    transform: scale(1.1) rotateZ(180deg);
    &::before {
      background-color: $square3-color;
      animation-delay: 0.6s;
    }
  }
  .sk-cube4 {
    transform: scale(1.1) rotateZ(270deg);
    &::before {
      background-color: $square4-color;
      animation-delay: 0.9s;
    }
  }
}

@keyframes sk-foldCubeAngle {
  0%,
  10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

.loader {
  border: 8px solid #f3f3f3; /* Bordure pleine */
  border-top: 8px solid $square4-color; /* Bordure en pointillés */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite; /* Animation de rotation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
